import * as API from '.'

export default {
    //你好作家列表
    getList: params => {
        return API.POST('api/writer/list', params)
    },
    //你好作家详情
    getDetails: id => {
        return API.POST(`api/writer/details/${id}`)
    },
    hellowritertype:params => {
        return API.POST('api/writer/type', params)
    },
}