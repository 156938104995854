import * as API from '.'
export default {
    // 获取阅读空间分类
    getType: () => {
        return API.POST('api/resources/type')
    },
    // 获取阅读空间详情
    getDetails: id => {
        return API.GET('api/resources/details/' + id)
    },
    // 获取阅读空间列表
    getList: params => {
        return API.POST('api/resources/list', params)
    },
}