import * as API from '.'

export default {
    //馆校合作公告
    getList: params => {
        return API.POST('api/cooperation/list', params)
    },
    //馆校合作详情
    getDetails: id => {
        return API.POST(`api/cooperation/details/${id}`)
    },
    cooperationSchooltype:params => {
        return API.POST('api/cooperation/type', params)
    },
}