import * as API from '.'

export default {
    //科普世界公告
    // 获取本馆概况分类
    getType: () => {
        return API.POST('api/popularizationOfScience/type')
    },
    // 获取科普世界详情
    getDetails: id => {
        return API.POST('api/popularizationOfScience/details/' + id)
    },
    // 获取科普世界列表
    getList: (params) => {
        return API.POST('api/popularizationOfScience/list', params)
    },
}