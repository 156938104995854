import * as API from '.'
export default {
    // 获取艺术空间分类
    getType: () => {
        return API.GET('api/artSpace/type')
    },
    // 获取艺术空间详情
    getDetails: id => {
        return API.GET('api/artSpace/details/' + id)
    },
    // 获取艺术空间列表
    getList: params => {
        return API.POST('api/artSpace/list', params)
    },
}