import * as API from '.'
export default {
    // 获取本馆概况分类
    getType: () => {
        return API.POST('api/generalSituation/type')
    },
    // 获取本馆概况详情
    getDetails: id => {
        return API.GET('api/generalSituation/details/' + id)
    },
    // 获取本馆概况列表
    getList: params => {
        return API.POST('api/generalSituation/list', params)
    },
}