import * as API from '.'

export default {
    //家庭教育公告
    getList: params => {
        return API.POST('api/eucation/list', params)
    },
    //家庭教育详情
    getDetails: id => {
        return API.POST(`api/eucation/details/${id}`)
    },
    //家庭教育类别
    getType: () => {
        return API.POST('api/eucation/type')
    },
}