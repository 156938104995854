<template>
  <div class="Detailvedio">
    <Header></Header>
    <Nav :fromPath="fromPath"></Nav>
    <!-- 顶部 -->
    <!-- 主体部分 -->
    <div class="banner">
      <p
        style="
          width: 1200px;
          margin: 20px auto 0px;
          color: black;
          font-size: 14px;
        "
      >
        <span @click="goHome">首页</span>
        <span @click="goBack">>{{ this.query }}</span>
        <span>></span>{{ titleName }}
      </p>
      <div class="left">
        <div class="shipin">
          <p class="newtitle">{{ titleName }}</p>
          <p class="newtime">
            发布时间：{{ alldata.createTime }}&nbsp;&nbsp;&nbsp;&nbsp;阅读量：{{
              alldata.pvNumber
            }}
          </p>
          <div class="chidlen" v-if="contentType == 1">
            <video
              controlslist="nodownload"
              oncontextmenu="return(false)"
              :src="content"
              width="100%"
              height="100%"
              controls
            ></video>
          </div>
          <div
            v-viewer
            class="fuwenben"
            :style="'padding:' + pad + 'px'"
            v-if="contentType == 0 || contentType == null"
            controlslist="nodownload"
            oncontextmenu="return(false)"
            v-html="content"
          ></div>
        </div>
        <div class="right" v-if="contentType == 0 || contentType == null">
          <h3 style="margin-top: 20px">热点推荐</h3>
          <div
            class="list"
            v-for="(i, k) in hotList"
            :key="k"
            @click="changePage(i)"
          >
            <img :src="i.imgUrl" alt="" />
            <p>{{ i.title }}</p>
          </div>
          <!-- <h3 style="margin-top: 40px">精彩视频</h3>
          <div class="list" @click="changePage(i.id)">
            <video :src="content" width="100%" height="100%" controls></video>
            <p>标题标题标题</p>
          </div> -->
        </div>
      </div>
    </div>
    <el-backtop :visibility-height="100"></el-backtop>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Api from "../api/api_home";
import Nav from "../components/nav.vue";
import apiGeneralSituation from "../api/api_general_situation";
import apiNews from "../api/api_news";
import apiPopularizationOfScience from "../api/api_popularization_of_science";
import apiEucation from "../api/api_eucation";
import apiArtSpace from "../api/api_art_space";
import apiRead from "../api/api_read";
import apiWriter from "../api/api_writer";
import apiCooperation from "../api/api_cooperation";
import apinavmenu from "../api/api_navmenu";
export default {
  name: "Detailvedio",
  data() {
    return {
      pad: "",
      titleName: "",
      fromPath: this.$route.query.fromPath,
      currentPage: 1,
      isactive: 0,
      pageNum: 1,
      pageSize: 12,
      viewType: "",
      viewslist: "",
      content: "",
      contentType: "",
      total: 0,
      viewtypelist: [
        {
          title: "全部",
          id: "",
        },
      ],
      hotList: [],
      query: "",
      alldata: "",
      articleId: "",
      all: [],
    };
  },
  components: {
    Header,
    Footer,
    Nav,
  },
  watch: {
    $route(to, from) {
      // 路由发生变化页面刷新
      // this.$router.go(0);
    },
  },
  created() {
    apinavmenu
      .getContentList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        classify: this.$route.query.classify,
      })
      .then((res) => {
        this.articleId = res.data.rows[0].contentId;
        Api.gethotDetails({ articleId: res.data.rows[0].contentId }).then(
          (res) => {
            if (res.code == 0) {
               	this.content = res.data.content.replace(/<img+/g, '<img style="max-width:100%;height:auto" referrerPolicy="no-referrer"').replace(/<video+/g, '<video referrerPolicy="no-referrer"')
            }
          }
        );
      });
    const {
      id,
      classify,
      childTitle,
      parentId,
      fromPath,
      title,
      contentType,
      tableId,
      articleId,
      title1,
    } = this.$route.query;
    this.query = this.$route.query.title;
    if (contentType) {
      this.contentType = this.$route.query.contentType;
      let params = {
        articleId: articleId,
      };
      Api.gethotDetails(params).then((res) => {
        if (res.code == 0) {
         	this.content = res.data.content.replace(/<img+/g, '<img style="max-width:100%;height:auto" referrerPolicy="no-referrer"').replace(/<video+/g, '<video referrerPolicy="no-referrer"')
          this.alldata = res.data;
        }
      });

      if (title) {
      } else {
        if (!id) {
          let params = {
            articleId: articleId,
          };
          Api.gethotDetails(params).then((res) => {
            if (res.code == 0) {
             	this.content = res.data.content.replace(/<img+/g, '<img style="max-width:100%;height:auto" referrerPolicy="no-referrer"').replace(/<video+/g, '<video referrerPolicy="no-referrer"')
              this.alldata = res.data;

              let bbb = {
                menuId: this.alldata.menuId,
              };

              Api.getMenufity(bbb).then((res) => {
                this.query = res.data[0].parentName;
              });
            }
          });
        } else {
          let aaa = {
            menuId: this.$route.query.id,
          };

          Api.getMenufity(aaa).then((res) => {
            this.query = res.data[0].parentName;
          });
        }
      }

      this.titleName = title1;
    } else {
      let params = {
        articleId: articleId,
      };
      Api.gethotDetails(params).then((res) => {
        if (res.code == 0) {
          	this.content = res.data.content.replace(/<img+/g, '<img style="max-width:100%;height:auto" referrerPolicy="no-referrer"').replace(/<video+/g, '<video referrerPolicy="no-referrer"')
          this.alldata = res.data;
        }
        this.contentType = this.alldata.contentType;
        let title = {
          menuId: this.alldata.menuId,
        };
        Api.getMenufity(title).then((res) => {
          this.query = res.data[0].parentName;
        });
      });
      this.titleName = title1;
    }
    this.gethot();
    this.padd();
  },
  methods: {
    goHome() {
      this.$router.push({
        path: "/",
      });
    },
    padd() {
      Api.Footer().then((res) => {
        this.pad = res.data.backGauge;
      });
    },
    goBack() {
      this.$router.push({
        path: "/list-text",
        query: {
          titleName: this.query,
          title: this.alldata.classifyTitle,
          classify: this.alldata.classify,
          menuId: this.alldata.menuId,
        },
      });
    },
    changePage(i) {
      this.$router.push({
        path: "/detail",
        query: {
          title: i.title,
          contentType: i.contentType,
          tableId: i.tableId,
          articleId: i.articleId,
        },
      });
    },
    gethot() {
      let params = {
        pageNum: 1,
        pageSize: 3,
      };
      Api.gethot(params).then((res) => {
        this.hotList = res.data.rows;
      });
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.pageNum = val;
    },
    changeActive($event) {},
    removeActive($event) {},
    checktab(index, id) {
      this.isactive = index;
      this.viewType = id;
    },
  },
};
</script>
<style scope>
.Detailvedio {
  width: 100%;
  height: 100%;
  background-color: #f7f6f2;
}
.fuwenben {
  /* width: 800px; */
  height: 100%;
  margin: 0px auto;
  background-color: #fff;

}
/* 内容 */
.Detailvedio .banner {
  width: 100%;
  height: auto;
  padding-bottom: 100px;
  min-height: 400px;
}
.chidlen {
  width: 950px;
}
.Detailvedio .banner .left {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: inline-block;
  margin-left: calc((100% - 1200px) / 2);
  margin-top: 20px;
}
.Detailvedio .banner .left .shipin {
  width: 950px;
  height: auto;
  display: inline-block;
  vertical-align: top;
}
.Detailvedio .banner .left .shipin .newtitle {
  background-color: #fff;
  text-align: center;
  padding: 10px 0px;
  padding-top: 15px;
  margin: 0px;
  font-weight: 700;
  font-size: 30px;
}
.Detailvedio .banner .left .shipin .newtime {
  background-color: #fff;
  margin: 0px;
  color: #999;
  font-size: 10px;
  text-align: center;
  padding: 10px;
}
.Detailvedio .banner .left .right {
  width: 230px;
  min-height: 570px;
  display: inline-block;
  vertical-align: top;
  padding: 15px 10px;
  background-color: #fff;
  cursor: pointer;
}
.Detailvedio .banner .left .right h3 {
  padding: 0px;
  margin: 0px;
  color: #000;
  font-size: 16px;
  margin-bottom: 15px;
}
.Detailvedio .banner .left .right .list {
  margin-bottom: 15px;
  cursor: pointer;
}
.Detailvedio .banner .left .right .list img {
  width: 100%;
  border-radius: 10px;
}
.Detailvedio .banner .left .right .list p {
  margin: 15px 0px;
  padding-left: 10px;
  font-size: 14px;
  color: #000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 25px;
}
</style>
